
<template>
  <header>
    <h3 v-for="message in messageAndErrorStore.readMessages" :key="message.key" :class="{ 'error': message.isError }">{{
      message.message }}</h3>
    <nav-bar>

      <router-link to="/">Home</router-link>
      <router-link to="/dashboard">Dashboard</router-link>
      <router-link to="/contact">Contact</router-link>
    </nav-bar>

  </header>

  <RouterView />
</template>

<script lang="ts" setup>
import { useMessageAndErrorStore } from './stores/useMessageAndErrorStore';
const messageAndErrorStore = useMessageAndErrorStore();
</script>

<style>
header {
  top: 0;
  line-height: 1.5;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
