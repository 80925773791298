<script setup lang="ts">
import { computed, onMounted, ref, type Ref } from 'vue';

const url = new URL("https://getcontent-kf6xfyi63a-uc.a.run.app");
url.searchParams.append("websiteId", "FtCbF7MQ9MV0LD1P412H");
url.searchParams.append("contentGroupId", "RruS6B61tt07ppsbS1L5");

const data: Ref<any[]> = ref([])

onMounted(async () => {
  const res = await fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json'
    }
  });
  data.value = (await res.json()).data;
});
const galleryData = computed(() => {
  return data.value.map(item => {
    return {
      ...item,
      images: item.images.slice(1)
    };
  });
});
function openWebsite(url: string) {
  window.open(
    url,
    '_blank'
  );
}
</script>

<template>
  <main>
    <h1>Welcome</h1>
    <h2>This is the website to update content on your website designed and built by Jacob.</h2>
    <br />
    <h2>Do you not yet have your website?</h2>
    <h3>Reach out to discuss your needs, options, and pricing.</h3>
    <h2>The Process</h2>
    <h3>
      Initially, we will arrange a meeting to discuss your specific requirements, desired timeline,
      and pricing. Since each website is unique in terms of content, page structure, and other
      factors, it's crucial to understand your individual needs. Some websites may require frequent
      updates, while others might only need occasional maintenance on a yearly basis. Additionally,
      certain websites prioritize the inclusion of online stores and blogs, while others may not
      require these features.
    </h3>
    <h3>
      Once I have a clear understanding of your requirements and we have reached a mutual agreement
      on pricing, I will commence the website design process. During this phase, I will provide you
      with mockups and prototypes, allowing you to visualize the website's appearance and
      functionality. Typically, the design phase takes around one to two weeks. After finalizing the
      design and agreeing on the price, depending on the scale of the project, I may request a
      deposit before proceeding to the development stage.
    </h3>
    <h3>
      Once development begins, it usually takes a few weeks or adheres to the agreed-upon timeframe
      to complete the website. Upon receiving the initial payment, the website will be deployed and
      made live for public access. Subsequently, whenever you require any modifications or updates,
      you can easily reach out to me, or if preferred, you can make changes through a designated
      portal that facilitates frequent or self-service modifications.
    </h3>
    <h2>Past Work</h2>
    <h3>
      Below are a few examples of the websites I have built. Please note that these are not
      exhaustive, as websites continually evolve with changing information, the addition of blog
      posts, new product arrivals, and more, all of which require ongoing maintenance and updates.
      These examples represent websites that are continuously developed and improved rather than
      fixed past projects.
    </h3>
    <div v-if="data.length > 0" class="work-card-wrapper">
      <div class="work-card" v-for="(item, itemIndex) in data" :key="item.id">
        <div class="work-main">
          <img
            :srcset="`${item.images[0].sizes[0].url} 320w, ${item.images[0].sizes[1].url} 480w, ${item.images[0].sizes[2].url} 720w, ${item.images[0].sizes[3].url} 1080w`"
            :alt="item.title + 'Website Thumbnail'" class="work-image">
          <div class="work-info">
            <h2>{{ item.title }}</h2>
            <h3>{{ item.description }}</h3>
            <button @click="openWebsite(item.content)">Visit Website</button>
          </div>

        </div>
        <div class="work-gallery">
          <div v-for="(image, index) in galleryData[itemIndex].images" :key="index" class="work-gallery-image-wrapper">
            <img class="work-gallery-image"
              :srcset="`${image.sizes[0].url} 320w, ${image.sizes[1].url} 480w, ${image.sizes[2].url} 720w, ${image.sizes[3].url} 1080w`" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>Loading...</h3>
    </div>
  </main>
</template>

<style scoped>
h3 {
  padding-bottom: 1rem;
}

.work-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.work-card {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border-radius: 18px;
  width: 100%;
}

.work-main {

  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;

}



.work-info {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.work-image {
  width: 50%;
  max-width: 600px;
  margin: auto;
  object-fit: contain;
}



.work-gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-items: center;
  gap: 1rem;
  margin: 1rem;
}

.work-gallery-image-wrapper {
  width: 50%;
}

.work-gallery-image {
  object-fit: cover;
  width: 100%;
}


@media screen and (min-width: 900px) {


  .work-main {
    flex-direction: row;
  }

  .work-image {
    margin: 1.5rem;
  }

  .work-gallery-image-wrapper {
    width: 25%;
  }


}
</style>
