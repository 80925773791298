import 'ress/dist/ress.min.css'

import './assets/main.css'

import * as Sentry from '@sentry/vue'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import App from './App.vue'
import './assets/nprogress.css'
import router from './router'

/* import the fontawesome core */
import { library, type IconDefinition } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faFacebookF, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import { faPlus, faPrint, faTrashCan, faStar as fasStar } from '@fortawesome/free-solid-svg-icons'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './firebase'

/* add icons to the library */
library.add(faTrashCan)
library.add(farStar)
library.add(fasStar)
library.add(faPlus)
library.add(faInstagram as IconDefinition)
library.add(faFacebookF as IconDefinition)
library.add(faXTwitter as IconDefinition)
library.add(faPrint as IconDefinition)

const app = createApp(App)

const isProduction = import.meta.env.PROD

initializeApp(firebaseConfig)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_APP_VERSION,
  environment: isProduction ? 'production' : 'development',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0
})

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(createPinia())

app.use(FloatingVue)

app.use(router)
app.mount('#app')
