export enum DbEditStatus {
  success,
  error
}

export class DbEditResponse {
  status: DbEditStatus
  message: string
  constructor(status: DbEditStatus, message: string) {
    this.status = status
    this.message = message
  }
}
