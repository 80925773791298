<template>
    <button name="Toggle Menu" @click="() => { showMenu = !showMenu }"> {{
        showMenu ? "Close Menu" : "Menu" }}</button>
    <nav :style="{ 'display': showMenu ? 'flex' : 'none' }">
        <slot />
    </nav>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const showMenu = ref(false);

</script>

<style scoped>
nav {
    width: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}


@media (min-width: 530px) {
    nav {
        flex-direction: row;
        display: flex !important;
    }

    button {
        display: none;
    }

}
</style>