// import type { Website } from '@/models/websites'
import { DbEditStatus, type DbEditResponse } from '@/models/db'
import { Preferences } from '@/models/preferences'
import router from '@/router'
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  getAuth,
  inMemoryPersistence,
  linkWithRedirect,
  onAuthStateChanged,
  setPersistence,
  signInWithRedirect
} from 'firebase/auth'
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import nProgress from 'nprogress'
import { defineStore } from 'pinia'
import { ref } from 'vue'
const provider = new GoogleAuthProvider()
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();

export const useAuthStore = defineStore('auth', () => {
  const db = getFirestore()
  const auth = getAuth()

  const user = ref(auth.currentUser)

  onAuthStateChanged(auth, (newUser) => {
    user.value = newUser
    if (newUser) {
      user.value = newUser
      router.push({ name: 'content' })
    } else {
      router.push({ name: 'login' })
    }
  })

  async function getPhoneNumber(): Promise<string | undefined> {
    nProgress.start()
    const userDoc = await getDoc(doc(db, 'users', user.value!.uid))
    nProgress.done()
    return userDoc.data()!['phone_number']
  }
    async function getEmail(): Promise<string | undefined> {
    nProgress.start()
    const userDoc = await getDoc(doc(db, 'users', user.value!.uid))
    nProgress.done()
    return userDoc.data()!['email']
  }
    async function getName(): Promise<string | undefined> {
    nProgress.start()
    const userDoc = await getDoc(doc(db, 'users', user.value!.uid))
    nProgress.done()
    return userDoc.data()!['name']
  }
  async function getPrefs(): Promise<Preferences> {
    nProgress.start()
    const userDoc = await getDoc(doc(db, 'users', user.value!.uid))
    const data = userDoc.data()

    const prefs = data!['preferences'] as Preferences
    if (prefs === undefined) {
      return new Preferences({
        deletion_sms: false,
        contact_sms: false,
        contact_email: false,
      })
    }
    if (prefs.deletion_sms === undefined) {
      prefs.deletion_sms = false
    }
    nProgress.done()
    return prefs
  }
  async function updatePrefs(newPrefs: Preferences): Promise<DbEditResponse> {
    nProgress.start()
    try {
      await setDoc(
        doc(db, 'users', user.value!.uid),
        {
          preferences: newPrefs.toObject()
        },
        { merge: true }
      )
      nProgress.done()
      return {
        status: DbEditStatus.success,
        message: 'Preferences updated successfully.'
      }
    } catch (e) {
      nProgress.done()

      return {
        status: DbEditStatus.error,
        message: 'Unknown Error.'
      }
    }
  }
  async function updatePhoneNumber(newNumber: String): Promise<DbEditResponse> {
    nProgress.start()
    try {
      await setDoc(
        doc(db, 'users', user.value!.uid),
        {
          phone_number: newNumber
        },
        { merge: true }
      )
      nProgress.done()
      return {
        status: DbEditStatus.success,
        message: 'Preferences updated successfully.'
      }
    } catch (e) {
      nProgress.done()

      return {
        status: DbEditStatus.error,
        message: 'Unknown Error.'
      }
    }
  }  async function updateEmail(newEmail: string): Promise<DbEditResponse> {
    nProgress.start()
    try {
      await setDoc(
        doc(db, 'users', user.value!.uid),
        {
          email: newEmail
        },
        { merge: true }
      )
      nProgress.done()
      return {
        status: DbEditStatus.success,
        message: 'Preferences updated successfully.'
      }
    } catch (e) {
      nProgress.done()

      return {
        status: DbEditStatus.error,
        message: 'Unknown Error.'
      }
    }
  }  async function updateName(newName: string): Promise<DbEditResponse> {
    nProgress.start()
    try {
      await setDoc(
        doc(db, 'users', user.value!.uid),
        {
          name: newName
        },
        { merge: true }
      )
      nProgress.done()
      return {
        status: DbEditStatus.success,
        message: 'Preferences updated successfully.'
      }
    } catch (e) {
      nProgress.done()

      return {
        status: DbEditStatus.error,
        message: 'Unknown Error.'
      }
    }
  }
  async function loginGoogle() {
    nProgress.start()
    if (!user.value) {
      await setPersistence(auth, inMemoryPersistence)
      await signInWithRedirect(auth, provider)
    }

    nProgress.done()
  }

  async function linkFacebook() {
    nProgress.start()
    if (user.value) {
     linkWithRedirect(auth.currentUser!, facebookProvider).then(() => {
      console.log('linked')
     }).catch((e) => {
      console.log(e)
      });
    }
    nProgress.done()
  }

    async function linkX() {
    nProgress.start()
    if (user.value) {
     linkWithRedirect(auth.currentUser!, twitterProvider).then(() => {
      console.log('linked')
     }).catch((e) => {
      console.log(e)
      });
    }
    nProgress.done()
  }

  async function logout() {
    nProgress.start()
    await auth.signOut()
    nProgress.done()
    user.value = null
  }

  return {
    user,
    getPhoneNumber,
    getName,
    getEmail,
    updatePhoneNumber,
    updateEmail,
    updateName,
    logout,
    login: loginGoogle,
    getPrefs,
    updatePrefs,
    linkFacebook,
    linkX,
  }
})
