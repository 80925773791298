import type { Message } from '@/models/message';
import { defineStore } from 'pinia';
import { computed, ref, type Ref } from 'vue';

export const useMessageAndErrorStore = defineStore('messageAndError', () => {
    const errors: Ref<Message[]> = ref([]);
                const currentBackgroundColor = ref()


    const addMessage = (message: Message) => {
        errors.value = [...errors.value, message]
        if (message.isError) {
            console.error(message.message)
            currentBackgroundColor.value = document.getElementsByTagName('body')[0].style.background
            document.getElementsByTagName('body')[0].style.background = 'red'
            setTimeout(() => {
                document.getElementsByTagName('body')[0].style.background = currentBackgroundColor.value
                deleteMessage(message.key);
            }, 5000)

        } else {
            setTimeout(() => {
                deleteMessage(message.key);
            }, 5000);
        }
    }

    const deleteMessage = (key: string) => {
        const messageToDelete = errors.value.find((message) => message.key === key)
        errors.value = errors.value.filter((message) => message.key !== messageToDelete?.key)
        if (messageToDelete?.isError && !hasError.value) {
            document.getElementsByTagName('body')[0].style.background = currentBackgroundColor.value
        }
    }

    const hasError = computed(() => {
        errors.value.forEach((e) => {
            if (e.isError) {
                return true;
            }
        })
        return false;
    })

    const readMessages = computed(() => {
        return [...errors.value];
    })

    return {
        addMessage,
        deleteMessage,
        readMessages,
        hasError
    }
})

