import { useAuthStore } from '@/stores/useAuthStore'
import NProgress from 'nprogress'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/ContactView.vue'),
      meta: {
        title: 'Contact'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/LoginView.vue'),
      meta: {
        title: 'Login'
      }
    },

    {
      path: '/guide',
      name: 'User Documentation',
      component: () => import('../views/UserDocumentationView.vue'),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('../views/DashboardView.vue'),
      children: [
        {
          path: 'stats',
          name: 'WebsiteStats',
          component: () => import('../views/dashboard_pages/StatsView.vue'),
          meta: {
            requiredTokens: ['google']
          }
        },
        {
          path: '',
          name: 'content',
          component: () => import('../views/dashboard_pages/ContentView.vue')
        },
                {
          path: '',
          name: 'forms',
          component: () => import('../views/dashboard_pages/FormsView.vue')
        },
        {
          path: 'settings',
          name: 'settings',
          component: () => import('../views/dashboard_pages/SettingsView.vue')
        }
      ],
      meta: {
        requiresAuth: true,
        title: 'Dashboard'
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  document.title = (to.meta.title as string) || '1x1 Websites'
  //   if (to.meta.requiredTokens) {
  //   const userStore = useAuthStore()
  //   if ((to.meta.requiredTokens as string[]).includes('google')) {
  //     console.log('google')
  //     if (userStore.googleToken === '') {
  //       await userStore.login()
  //       // router.push({ name: 'WebsiteStats' })

  //     }
  //     return next()
  //   }
  // }
  if (to.meta.requiresAuth) {
    const userStore = useAuthStore()
    const user = userStore.user
    if (!user) {
      router.push({ name: 'login' })
    }
    return next()
  } else {
    return next()
  }


})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
