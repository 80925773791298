export class Preferences {
  deletion_sms: boolean
  contact_sms: boolean
  contact_email: boolean
  toObject() {
    return {
      deletion_sms: this.deletion_sms,
      contact_sms: this.contact_sms,
      contact_email: this.contact_email
    }
  }
  constructor({ deletion_sms, contact_sms, contact_email }: { deletion_sms: boolean, contact_sms: boolean, contact_email: boolean }) {
    this.deletion_sms = deletion_sms
    this.contact_sms = contact_sms,
    this.contact_email = contact_email
  }
}
